<template>
  <div class="filter-button" @click="$emit('click', id)">
    <div class="filter-button__icon">
      <img v-if="iconSrc" :src="iconSrc" alt="icon" />
    </div>
    <div class="filter-button__text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'FilterButton',
  props: {
    id: {
      type: String,
      default: 'btn',
    },
    text: {
      type: String,
      default: 'btnText',
    },
    iconSrc: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.filter-button {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: solid 2.4px #aeaeae;
  padding: 0.68em 1.43em;
  cursor: pointer;

  &__icon {
    margin-right: 0.85em;
    width: 1.75em;
  }

  &__text {
    text-transform: capitalize;
    font-size: 1.12em;
    color: #4b4b4b;
    font-family: Circular-Std-Book, sans-serif;
    font-weight: 500;
  }

  @media screen and (max-width: 467px) {
    font-size: 0.7rem;
  }
}
</style>
