<template>
  <div class="stats-filter">
    <div class="stats-filter-mobile__header">
      <div class="stats-filter-mobile__header__title">
        filtrar estadìsticas
      </div>
      <div class="stats-filter-mobile__header__back" @click="$emit('close')">
        <div class="stats-filter-mobile__header__back__icon">
          <img src="/icons/icn_back.svg" alt="back" />
        </div>
        <div class="stats-filter-mobile__header__back__text">volver</div>
      </div>
    </div>
    <div class="stats-filter__header">
      <div class="stats-filter__header__title">
        filtrar estadìsticas
      </div>
      <div class="stats-filter__header__close" @click="$emit('close')">
        <img src="/icons/icn-close-dark.svg" alt="close" width="15" />
      </div>
    </div>
    <div class="stats-filter__parent-stats">
      <div class="stats-filter__parent-stats__title">
        POSESIÓN
      </div>
      <div class="stats-filter__parent-stats__check">
        <BCheckbox v-model="categories[0].category.checked" />
      </div>
    </div>
    <template v-for="category in categories[0].group_categories">
      <div class="stats-filter__item-stats-row__item-col border-medium" :key="category.id">
        <div class="stats-filter__item-stats-row__item-col__title">{{ category.name }}</div>
        <div class="stats-filter__item-stats-row__item-col__check">
          <BCheckbox :id="category.id" v-model="category.checked" />
        </div>
      </div>
    </template>
    <div class="stats-filter__parent-stats">
      <div class="stats-filter__parent-stats__title">
        OFENSIVA
      </div>
      <div class="stats-filter__parent-stats__check">
        <BCheckbox v-model="categories[1].category.checked" />
      </div>
    </div>
    <template v-for="category in categories[1].group_categories">
      <div class="stats-filter__item-stats-row__item-col border-medium" :key="category.id">
        <div class="stats-filter__item-stats-row__item-col__title">{{ category.name }}</div>
        <div class="stats-filter__item-stats-row__item-col__check">
          <BCheckbox :id="category.id" v-model="category.checked" />
        </div>
      </div>
    </template>
    <div class="stats-filter__parent-stats">
      <div class="stats-filter__parent-stats__title">
        DEFENSIVA
      </div>
      <div class="stats-filter__parent-stats__check">
        <BCheckbox v-model="categories[2].category.checked" />
      </div>
    </div>
    <template v-for="category in categories[2].group_categories">
      <div class="stats-filter__item-stats-row__item-col border-medium" :key="category.id">
        <div class="stats-filter__item-stats-row__item-col__title">
          <template v-if="category.name != 'none'">{{ category.name }}</template
          ><template v-else>-</template>
        </div>
        <div class="stats-filter__item-stats-row__item-col__check">
          <template v-if="category.name != 'none'">
            <BCheckbox :id="category.id" v-model="category.checked" />
          </template>
        </div>
      </div>
    </template>
    <div class="stats-filter__footer">
      <div class="stats-filter__footer__button" @click="onButtonClicked">OK</div>
    </div>
  </div>
</template>

<script>
import { BCheckbox } from 'buefy/dist/esm/checkbox';
export default {
  name: 'CategoriesFilterDesktop',
  components: {
    BCheckbox,
  },
  props: {
    categories: {
      type: Object,
      required: true,
    },
  },
  computed: {
    catPosesion() {
      return this.categories[0].category.checked;
    },
    catOfensiva() {
      return this.categories[1].category.checked;
    },
    catDefensiva() {
      return this.categories[2].category.checked;
    },
    catGroupPosesion() {
      return this.valSelectCheck(0);
    },
    catGroupOfensiva() {
      return this.valSelectCheck(1);
    },
    catGroupDefensiva() {
      return this.valSelectCheck(2);
    },
  },
  watch: {
    catPosesion() {
      this.selectChecks(0, this.catPosesion);
    },
    catOfensiva() {
      this.selectChecks(1, this.catOfensiva);
    },
    catDefensiva() {
      this.selectChecks(2, this.catDefensiva);
    },
    catGroupPosesion() {
      this.selectCheck(0, this.catGroupPosesion);
    },
    catGroupOfensiva() {
      this.selectCheck(1, this.catGroupOfensiva);
    },
    catGroupDefensiva() {
      this.selectCheck(2, this.catGroupDefensiva);
    },
  },
  methods: {
    onButtonClicked() {
      this.$emit('apply-categories-filter');
    },
    selectChecks(id, type) {
      if (type) {
        Object.keys(this.categories[id].group_categories).forEach(key => {
          this.categories[id].group_categories[key].checked = true;
        });
      } else {
        const asArray = Object.entries(this.categories[id].group_categories);
        const filtered = asArray.filter(function(e) {
          return e[1].checked;
        });
        if (filtered.length == asArray.length) {
          Object.keys(this.categories[id].group_categories).forEach(key => {
            this.categories[id].group_categories[key].checked = false;
          });
        }
      }
    },
    selectCheck(id, type) {
      if (type) {
        this.categories[id].category.checked = true;
      } else {
        this.categories[id].category.checked = false;
      }
    },
    valSelectCheck(id) {
      const asArray = Object.entries(this.categories[id].group_categories);
      const filtered = asArray.filter(function(e) {
        return e[1].checked;
      });
      if (filtered.length == asArray.length) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.stats-filter-mobile {
  &__header {
    display: none;
    position: relative;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background-color: #4b4b4b;
    color: white;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      display: flex;
    }

    &__title {
      font-size: 1.125rem;
      margin: 0.8rem 0;
      @media screen and (max-width: 474px) {
        font-size: 0.957rem;
      }
    }

    &__back {
      display: flex;
      position: absolute;
      left: 23px;

      &__icon {
        height: 19px;
      }

      &__text {
        margin-top: 0.1rem;
        margin-left: 0.5rem;
        font-size: 0.85rem;
        font-family: Circular-Std-Book, sans-serif;

        @media screen and (max-width: 474px) {
          display: none;
        }
      }
    }
  }
}
.border-medium {
  border-top: 1px solid #e7e7e7;
}
.stats-filter {
  margin: 1rem;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background: white;
  border-radius: 24px;
  &__header {
    @media screen and (max-width: 767px) {
      display: none;
    }
    width: 100%;
    background-color: #edefef;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 1.75rem 0;

    box-shadow: 0 25px 38px 0 rgba(0, 0, 0, 0.28);

    &__title {
      margin: 0 auto;
      font-size: 1.2rem;
      text-transform: uppercase;
    }
    &__close {
      position: absolute;
      right: 2.5rem;
      top: 2rem;
      cursor: pointer;
    }
  }
  &__footer {
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    padding: 2rem;

    &__button {
      background-color: #4b4b4b;
      color: white;
      padding: 0.875rem 7.3rem;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
      cursor: pointer;
    }

    @media screen and (max-width: 767px) {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      padding: 1.25rem;
    }
  }
  &__parent-stats {
    height: 40px;
    width: 100%;
    text-align: center;
    background-color: #edefef;
    display: inline-flex;
    @media screen and (max-width: 767px) {
      text-align: left;
    }
    &__title {
      margin: 0 auto;
      font-family: 'Avenir-Demi';
      font-size: 14px;
      line-height: 40px;
      height: 40px;
      color: #4b4b4b;
      text-transform: uppercase;
      @media screen and (max-width: 767px) {
        width: 100%;
        padding-left: 15px;
      }
    }
    &__check {
      float: right;
      margin-right: 19px;
      margin-top: 10px;
      @media screen and (max-width: 767px) {
        margin-right: 0px;
      }
    }
  }
  &__item-stats-row {
    height: 40px;
    width: 100%;
    background-color: #fff;
    display: inline-flex;
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 80px;
      display: inline-block;
    }
    &__item-col {
      height: 40px;
      width: 50%;
      float: left;
      display: inline-flex;
      background: white;

      &__title {
        font-family: 'Avenir-Medium';
        float: left;
        font-size: 15px;
        text-align: left;
        line-height: 40px;
        color: #545454;
        width: 92%;
        height: 40px;
        margin-left: 5%;

        @media screen and (max-width: 767px) {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      &__check {
        float: right;
        margin-top: 10px;
        margin-right: 19px;
        @media screen and (max-width: 767px) {
          margin-right: 0px;
        }
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-left: 0%;
        display: flex;
      }
    }
  }
}
</style>
