<template>
  <Tooltip :show="show" :show-close="isMobile" :x="x" :y="y" @close="onClose">
    <template v-slot:header>
      {{ playData.categoryName }}
    </template>
    <template v-slot:body>
      <div class="tooltip-body">
        <div class="tooltip-body__team">
          <div class="tooltip-body__team__logo">
            <img :src="playData.team.logo" alt="team logo" />
          </div>
          <div class="tooltip-body__team__name">{{ playData.team.teamName }}</div>
        </div>
        <div class="tooltip-body__info">
          <span class="name">Jugador:</span><span class="data">{{ playData.playerName }}</span>
        </div>
        <div class="tooltip-body__info">
          <span class="name">Minuto:</span><span class="data">{{ playData.minute }}</span>
        </div>
        <div class="tooltip-body__info rival-logo">
          <span class="name">Rival:</span>
          <span class="data">
            <span class="image is-24x24 is-inline-flex is-marginless leader-logo">
              <img :src="playData.rivalLogo" alt="rival logo" />
            </span>
          </span>
        </div>
        <!--    temporally, xG missing    -->
        <!--        <div class="tooltip-body__info">-->
        <!--          <span class="name">xGi:</span><span class="data">{{ playData.xGi }}</span>-->
        <!--        </div>-->
        <!--        <div class="tooltip-body__info">-->
        <!--          <span class="name">xGf:</span><span class="data">{{ playData.xGf }}</span>-->
        <!--        </div>-->
      </div>
    </template>
  </Tooltip>
</template>

<script>
import Tooltip from '@/components/Elements/NextMatches/Tooltip';

export default {
  name: 'PlayByPlayTooltip',
  components: {
    Tooltip,
  },
  props: {
    show: {
      type: Boolean,
      required: false,
    },
    playData: {
      type: Object,
      default: () => {},
    },
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isMobile() {
      return this.$mq === 'mobile';
    },
  },
  methods: {
    onClose() {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style scoped lang="scss">
.tooltip-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.8rem 2rem;

  &__team {
    display: grid;
    grid-template-columns: 24px 1fr;
    margin-bottom: 0.5rem;

    &__logo {
      height: 24px;
      width: 24px;

      & > img {
        object-fit: contain;
      }
    }

    &__name {
      text-align: left;
      text-transform: uppercase;
      margin-left: 0.5rem;
      padding-top: 0.2rem;
    }
  }

  &__info {
    margin: 0.2rem 0;
    font-size: 0.75rem;
    color: white;
    display: flex;

    & > span {
      display: block;

      &.name {
        font-family: Avenir-Demi, sans-serif;
      }

      &.data {
        text-align: left;
        font-family: Avenir-Regular, sans-serif;
        margin-left: 0.5rem;
      }
    }

    &.rival-logo {
      align-items: center;
    }
  }
}
</style>
