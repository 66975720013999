export default [
  {
    name: 'Goles',
    color: '#26C6DA',
    points: [1, 2, 3],
    categories: [12, 55, 35, 54],
    negative: false,
  },
  {
    name: 'Tiro a gol',
    color: '#FFFF00',
    points: [1, 2, 3],
    categories: [510],
    negative: false,
  },
  {
    name: 'Tiro a gol (portería)',
    color: '#9933FF',
    points: [1, 2, 3],
    categories: [91, 95],
    negative: false,
  },

  {
    name: 'Centro',
    color: '#FFA726',
    points: [1, 2, 3],
    categories: [13, 25],
    negative: false,
  },
  {
    name: 'Centro acertado',
    color: '#4DB6AC',
    points: [1, 2, 3],
    categories: [98, 101, 104, 105, 109, 112, 115, 116],
    negative: false,
  },
  {
    name: 'Balon ganado en área rival',
    color: '#F06292',
    points: [1, 2],
    categories: [11],
    negative: false,
  },
  {
    name: '1vs1 exitoso ofensivos',
    color: '#3A3838',
    points: [1],
    categories: [4],
    negative: false,
  },
  {
    name: '1vs1 no exitoso ofensivos',
    color: '#3A3838',
    points: [1],
    categories: [208],
    negative: false,
  },
  {
    name: 'Pases acertados',
    color: '#00B050',
    points: [1, 2, 3],
    categories: [213, 214, 215, 216],
    negative: false,
  },
  {
    name: 'Pases no acertados',
    color: '#00B050',
    points: [1, 2, 3],
    categories: [3, 21],
    negative: false,
  },
  {
    name: 'Balones perdidos',
    color: '#F06292',
    points: [1, 2],
    categories: [7, 22],
    negative: false,
  },
  {
    name: 'Balones recuperados',
    color: '#F06292',
    points: [1, 2],
    categories: [485, 486, 487, 488, 489, 490, 491, 492],
    negative: false,
  },
  {
    name: 'Balones recuperados (en disputa)',
    color: '#F06292',
    points: [1, 2],
    categories: [485, 486, 487, 488],
    negative: false,
  },
  {
    name: 'Balones recuperados (sin disputa)',
    color: '#B4005A',
    points: [1, 2],
    categories: [489, 490, 491, 492],
    negative: false,
  },
  {
    name: '1vs1 exitosos defensivos',
    color: '#455A64',
    points: [1],
    categories: [5],
    negative: false,
  },
  {
    name: '1vs1 no exitosos defensivos',
    color: '#4CAF50',
    points: [1],
    categories: [209],
    negative: false,
  },
  {
    name: 'Balones ganados en área propia',
    color: '#305496',
    points: [1, 2],
    categories: [23],
    negative: false,
  },
  {
    name: 'Juego aéreo resto del campo',
    color: '#FF9966',
    points: [1, 2, 3],
    categories: [148, 204, 505],
    negative: false,
  },
  {
    name: 'Centros permitidos',
    color: '#FFA726',
    points: [1, 2, 3],
    categories: [517],
    negative: false,
  },
  {
    name: 'Rechaces',
    color: '#700000',
    points: [1],
    categories: [34],
    negative: false,
  },
  {
    name: 'Barridas',
    color: '#CC0000',
    points: [1],
    categories: [518],
    negative: false,
  },
  {
    name: 'Fuera de lugar provocados',
    color: '#00FF00',
    points: [1, 2, 3],
    rivalCategories: [33],
    negative: true,
  },
];
