<template>
  <BModal
    :active="showModal"
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    :can-cancel="['outside', 'escape']"
    @close="$emit('close')"
  >
    <div id="categories-filter" class="categories-filter">
      <CategoriesFilterDesktop
        @apply-categories-filter="$emit('apply-categories-filter')"
        :categories="categories"
        @close="$emit('close')"
      />
    </div>
  </BModal>
</template>

<script>
import CategoriesFilterDesktop from '@/components/Elements/NextMatches/CategoriesFilterDesktop';
import { BModal } from 'buefy/dist/esm/modal';
export default {
  name: 'CategoriesFilterModal',
  components: {
    BModal,
    CategoriesFilterDesktop,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
#categories-filter {
  $primary: #7dd421;
  // checkboxes
  .b-checkbox.checkbox input[type='checkbox']:checked + .check {
    background: $primary
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:white' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center center;
    border-color: $primary;
  }

  .b-checkbox.checkbox:hover input[type='checkbox']:not(:disabled) + .check {
    border-color: $primary;
  }

  .b-checkbox.checkbox input[type='checkbox']:focus:checked + .check {
    box-shadow: 0 0 0.5em rgba(125, 212, 33, 0.8);
  }
}
</style>
