<template>
  <div class="filters">
    <div v-for="button in filtersButtons" :key="button.id" class="filters__button">
      <FilterButton :id="button.id" :text="button.text" :icon-src="button.iconSrc" @click="onButtonClicked" />
    </div>
    <div class="filters__title">
      filtrar
    </div>

    <PlayersFilterModal
      :teams="teams"
      :show-modal="showPlayersFilter"
      :game-players="gamePlayers"
      @close="onModalClose"
      @apply-players-filter="$emit('apply-players-filter')"
    />

    <CategoriesFilterModal
      :key="updatemodalCategory"
      @apply-categories-filter="onclickAppyCategories"
      :categories="categories"
      :show-modal="showStatsFilter"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import FilterButton from '@/components/Elements/buttons/FilterButton';
import PlayersFilterModal from '@/components/Modals/PlayersFilterModal';
import CategoriesFilterModal from '@/components/Modals/CategoriesFilterModal';

export default {
  name: 'PlayByPlayFilters',
  components: {
    FilterButton,
    PlayersFilterModal,
    CategoriesFilterModal,
  },
  props: {
    categories: {
      type: Object,
      required: true,
    },
    gameData: {
      type: Object,
      required: true,
    },
    gamePlayers: {
      type: Object,
      default: () => {
        return {
          homeTeamPlayers: [],
          awayTeamPlayers: [],
        };
      },
    },
  },
  data() {
    return {
      updatemodalCategory: 0,
      filtersButtons: [
        {
          id: 'players',
          text: 'jugadores',
          iconSrc: '/icons/icn_shirt.svg',
        },
        {
          id: 'stats',
          text: 'estadísticas',
          iconSrc: '/icons/icn_stats.svg',
        },
      ],
      showPlayersFilter: false,
      showStatsFilter: false,
    };
  },
  computed: {
    teams() {
      return {
        homeTeam: this.gameData.homeTeam,
        awayTeam: this.gameData.awayTeam,
      };
    },
  },
  methods: {
    onclickAppyCategories() {
      this.showPlayersFilter = false;
      this.showStatsFilter = false;
      this.$emit('apply-categories-filter');
    },
    onButtonClicked(btnId) {
      if (btnId === 'players') {
        this.showPlayersFilter = true;
      } else if (btnId === 'stats') {
        this.showStatsFilter = true;
      }
    },
    onModalClose() {
      this.showPlayersFilter = false;
      this.showStatsFilter = false;
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.filters {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.87rem 0;

  &__button {
    margin: 0 1.25rem;
  }

  &__title {
    position: absolute;
    text-transform: uppercase;
    font-size: 0.87rem;
    color: white;
    background-color: #2c2b2b;
    font-family: Circular-Std-Book, sans-serif;
    padding: 0.15rem 1.75rem 0.1rem;
    top: -0.8rem;
    border-radius: 12px;
  }
}
</style>
