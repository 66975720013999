<template>
  <div class="players-filter-desktop">
    <div class="players-filter-desktop__header">
      <div class="players-filter-desktop__header__title">
        filtrar jugadores
      </div>
      <div class="players-filter-desktop__header__close" @click="$emit('close')">
        <img src="/icons/icn-close-dark.svg" alt="close" width="15" />
      </div>
    </div>
    <div class="players-filter-desktop__teams">
      <div class="players-filter-desktop__teams__team">
        <div class="players-filter-desktop__teams__team__name">{{ teams.homeTeam.team_name }}</div>
        <div class="players-filter-desktop__teams__team__logo">
          <img :src="teams.homeTeam.logo" alt="Equipo Local" />
        </div>
      </div>
      <div class="players-filter-desktop__teams__divider"></div>
      <div class="players-filter-desktop__teams__team">
        <div class="players-filter-desktop__teams__team__logo">
          <img :src="teams.awayTeam.logo" alt="Equipo Visitante" />
        </div>
        <div class="players-filter-desktop__teams__team__name">{{ teams.awayTeam.team_name }}</div>
      </div>
    </div>
    <div class="players-filter-desktop__players-lists">
      <div>
        <PlayersFilterSubHeader />
        <div class="players">
          <PlayersFilterRow v-for="(player, index) in gamePlayers.homeTeamPlayers" :key="index" :player="player" />
        </div>
      </div>
      <div>
        <PlayersFilterSubHeader />
        <div class="players">
          <PlayersFilterRow v-for="(player, index) in gamePlayers.awayTeamPlayers" :key="index" :player="player" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlayersFilterSubHeader from '@/components/Elements/PlayersFilterSubHeader';
import PlayersFilterRow from '@/components/Elements/PlayersFilterRow';

export default {
  name: 'PlayersFilterDesktop',
  components: {
    PlayersFilterSubHeader,
    PlayersFilterRow,
  },
  props: {
    teams: {
      type: Object,
      required: true,
    },
    gamePlayers: {
      type: Object,
      default: () => {
        return {
          homeTeamPlayers: [],
          awayTeamPlayers: [],
        };
      },
    },
  },
};
</script>

<style scoped lang="scss">
.players-filter-desktop {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  &__header {
    width: 100%;
    background-color: #edefef;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding: 1.75rem 0;
    position: relative;

    &__title {
      margin: 0 auto;
      font-size: 1.2rem;
      text-transform: uppercase;
    }

    &__close {
      position: absolute;
      right: 1.5rem;
      top: 1rem;
      cursor: pointer;
    }
  }

  &__teams {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 5px 1fr;
    background-color: white;
    padding: 1rem 0;

    &__team {
      display: flex;
      justify-content: center;
      align-items: center;

      &__logo {
        width: 48px;
        height: 48px;
        margin: 0 1rem;
        flex-shrink: 0;
      }

      &__name {
        margin: 0 1rem;
        text-transform: uppercase;
        font-family: Circular-Std-Book, sans-serif;
        width: fit-content;
        flex-shrink: 1;
      }
    }

    &__divider {
      height: 2rem;
      border-left: dotted 1px #878787;
      align-self: center;
    }
  }

  &__players-lists {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: white;

    & .players {
      padding: 0 0.5rem;
    }
  }
}
</style>
