<template>
  <div class="component-header">
    <div class="component-header__title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'ComponentHeader',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
  },
};
</script>

<style scoped lang="scss">
.component-header {
  width: 100%;
  background-color: #2c2b2b;
  text-align: center;
  padding: 14px 0;

  &__title {
    color: white;
    font-family: Circular-Std-Book, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
  }
}
</style>
