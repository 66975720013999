<template>
  <div class="play-by-play">
    <PlayByPlayField
      v-if="false"
      :game-data="gameData"
      :filtered-players-ids="filteredPlayersIds"
      :filtered-categories-ids="filteredCategoriesIds"
      @players-fetched="onPlayersFetched"
    />
    <Filters
      v-if="false"
      :categories="categories"
      :game-data="gameData"
      :game-players="gamePlayers"
      @close="closeCategories"
      @apply-categories-filter="onApplyCategoriesFilter"
      @apply-players-filter="onApplyPlayersFilter"
    />
    <ComponentHeader title="timeline" />
    <TimeLine :game-data="gameData" @player-changed="onPlayerChanged" />
  </div>
</template>

<script>
import TimeLine from '@/components/Elements/TimeLine';
import Filters from '@/components/Elements/PlayByPlayFilters';
import ComponentHeader from '@/components/Layout/ComponentHeader';
import PlayByPlayField from '@/components/Elements/PlayByPlayField';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'PlayByPlay',
  components: {
    PlayByPlayField,
    ComponentHeader,
    TimeLine,
    Filters,
  },
  data() {
    return {
      cloneCategories: {},
      flagClone: 0,
      categories: {
        0: {
          category: { name: 'POSESIÓN', checked: false },
          group_categories: {
            1: { name: 'Pases acertados', id: 1, checked: false, flag: null, valuesSend: [213, 214, 215, 216] },
            2: {
              name: 'Balón recuperado',
              id: 2,
              checked: false,
              flag: null,
              valuesSend: [485, 486, 487, 488, 489, 490, 491, 492],
            },
            3: { name: 'Pases no acertados', id: 3, checked: false, flag: null, valuesSend: [3, 21] },
            4: { name: 'Balón perdido', id: 4, checked: false, flag: null, valuesSend: [7, 22] },
          },
        },
        1: {
          category: { name: 'OFENSIVA', checked: true },
          group_categories: {
            1: { name: 'Goles', id: 5, checked: true, flag: null, valuesSend: [12, 35, 54, 55] },
            2: {
              name: 'Centros acertados',
              id: 6,
              checked: true,
              flag: null,
              valuesSend: [98, 101, 104, 105, 109, 112, 115, 116],
            },
            3: { name: 'Tiros a gol', id: 7, checked: true, flag: null, valuesSend: [510] },
            4: { name: 'Balones ganados en área rival', id: 8, checked: true, flag: null, valuesSend: [11] },
            5: { name: 'Tiros a gol (Portería)', id: 9, checked: true, valuesSend: [91, 95] },
            6: { name: '1vs1 exitosos ofensivos', id: 10, checked: true, flag: null, valuesSend: [4] },
            7: { name: 'Centros totales', id: 11, checked: true, flag: null, valuesSend: [13, 25] },
            8: { name: '1vs1 no exitosos ofensivos', id: 12, checked: true, flag: null, valuesSend: [208] },
          },
        },
        2: {
          category: { name: 'DEFENSIVA', checked: false },
          group_categories: {
            2: {
              name: 'Balones recuperados (sin disputa)',
              id: 28,
              checked: false,
              flag: null,
              valuesSend: [485, 486, 487, 488],
            },
            4: { name: '1vs1 exitosos defensivos', id: 14, checked: false, flag: null, valuesSend: [5] },
            6: { name: '1vs1 no exitosos defensivos', id: 16, checked: false, flag: null, valuesSend: [209] },
            8: { name: 'Balones ganados en área propia', id: 18, checked: false, flag: null, valuesSend: [23] },
            10: {
              name: 'Juego aéreo resto del campo',
              id: 20,
              checked: false,
              flag: null,
              valuesSend: [148, 204, 505],
            },
            11: { name: 'Fuera de lugar provocados', id: 21, checked: false, flag: null, valuesSend: [33] },
            12: { name: 'Barridas', id: 29, checked: false, flag: null, valuesSend: [518] },
            13: {
              name: 'Balones recuperados (en disputa)',
              id: 22,
              checked: false,
              flag: null,
              valuesSend: [485, 486, 487, 488],
            },
            14: { name: 'Rechaces', id: 44, checked: false, flag: null, valuesSend: [34] },
          },
        },
      },
      gameData: {},
      gamePlayers: {
        homeTeamPlayers: [],
        awayTeamPlayers: [],
      },
      filteredPlayersIds: [],
      filteredCategoriesIds: [],
      playerChangeEvents: [],
    };
  },
  computed: {
    ...mapState('nextGeneral', ['gameForAnalysis']),
    ...mapGetters('nextGeneral', ['homeTeam', 'visitingTeam', 'menuOptionActive']),
    clonedCategories: function() {
      return JSON.parse(JSON.stringify(this.categories));
    },
  },
  watch: {
    clonedCategories: function(newVal, oldVal) {
      if (this.flagClone == 0) {
        this.flagClone = 1;
        this.cloneCategories = oldVal;
      }
      this.diff(newVal);
    },
  },
  methods: {
    diff(newA) {
      const asArrayPosession = Object.entries(newA[0].group_categories);
      const asOriginArrayPosession = Object.entries(this.cloneCategories[0].group_categories);
      for (let index = 0; index < asArrayPosession.length; index++) {
        if (asArrayPosession[index][1].checked != asOriginArrayPosession[index][1].checked) {
          this.categories[0].group_categories[asArrayPosession[index][0]].flag = true;
        } else {
          this.categories[0].group_categories[asArrayPosession[index][0]].flag = null;
        }
      }
      const asArrayDefensive = Object.entries(newA[1].group_categories);
      const asOriginArrayDefensive = Object.entries(this.cloneCategories[1].group_categories);
      for (let indexD = 0; indexD < asArrayDefensive.length; indexD++) {
        if (asArrayDefensive[indexD][1].checked != asOriginArrayDefensive[indexD][1].checked) {
          this.categories[1].group_categories[asArrayDefensive[indexD][0]].flag = true;
        } else {
          this.categories[1].group_categories[asArrayDefensive[indexD][0]].flag = null;
        }
      }
      const asArrayOfensive = Object.entries(newA[2].group_categories);
      const asOriginArrayOfensive = Object.entries(this.cloneCategories[2].group_categories);
      for (let index = 0; index < asArrayOfensive.length; index++) {
        if (asArrayOfensive[index][1].checked != asOriginArrayOfensive[index][1].checked) {
          this.categories[2].group_categories[asArrayOfensive[index][0]].flag = true;
        } else {
          this.categories[2].group_categories[asArrayOfensive[index][0]].flag = null;
        }
      }
    },
    closeCategories() {
      // this.flagClone = 0;
      const asPosession = Object.entries(this.categories[0].group_categories);
      for (let index = 0; index < asPosession.length; index++) {
        if (asPosession[index][1].flag) {
          this.categories[0].group_categories[asPosession[index][0]].checked = !this.categories[0].group_categories[
            asPosession[index][0]
          ].checked;
          this.categories[0].group_categories[asPosession[index][0]].flag = null;
        }
      }

      const asDefensive = Object.entries(this.categories[1].group_categories);
      for (let index = 0; index < asDefensive.length; index++) {
        if (asDefensive[index][1].flag) {
          this.categories[1].group_categories[asDefensive[index][0]].checked = !this.categories[1].group_categories[
            asDefensive[index][0]
          ].checked;
          this.categories[1].group_categories[asDefensive[index][0]].flag = null;
        }
      }

      const asOfensive = Object.entries(this.categories[2].group_categories);
      for (let index = 0; index < asOfensive.length; index++) {
        if (asOfensive[index][1].flag) {
          this.categories[2].group_categories[asOfensive[index][0]].checked = !this.categories[2].group_categories[
            asOfensive[index][0]
          ].checked;
          this.categories[2].group_categories[asOfensive[index][0]].flag = null;
        }
      }
    },
    onPlayersFetched({ homeTeamPlayers, awayTeamPlayers }) {
      this.gamePlayers.homeTeamPlayers = homeTeamPlayers.map(player => {
        player.isSelected = true;
        return player;
      });
      this.gamePlayers.awayTeamPlayers = awayTeamPlayers.map(player => {
        player.isSelected = true;
        return player;
      });
      if (this.playerChangeEvents.length) {
        this.playerChangeEvents.forEach(playerChange => {
          this.onPlayerChanged(playerChange, true);
        });
      } else {
        this.onApplyPlayersFilter();
      }
    },
    onApplyPlayersFilter() {
      this.filteredPlayersIds = this.getPlayersIds(this.gamePlayers.homeTeamPlayers).concat(
        this.getPlayersIds(this.gamePlayers.awayTeamPlayers),
      );
    },
    onApplyCategoriesFilter() {
      this.flagClone = 0;
      this.filteredCategoriesIds = this.getCategoriesIds(0)
        .concat(this.getCategoriesIds(1))
        .concat(this.getCategoriesIds(2));
    },
    onPlayerChanged($event, skipPush) {
      if (!skipPush) {
        this.playerChangeEvents.push($event);
      }
      this.updateGamePlayers(
        $event.teamId === this.gameData.homeTeam.team_id
          ? this.gamePlayers.homeTeamPlayers
          : this.gamePlayers.awayTeamPlayers,
        $event.playerOut,
        $event.playerIn,
      );
      this.onApplyPlayersFilter();
    },
    getPlayersIds(players) {
      return players.reduce((accumulator, player) => {
        if (player.isSelected && player.isActive) {
          accumulator.push(player.player_id);
        }
        return accumulator;
      }, []);
    },
    getCategoriesIds(id) {
      let filtered = [];
      const asArray = Object.entries(this.categories[id].group_categories);
      for (let index = 0; index < asArray.length; index++) {
        if (asArray[index][1].checked) {
          for (let index2 = 0; index2 < asArray[index][1].valuesSend.length; index2++) {
            filtered.push(asArray[index][1].valuesSend[index2]);
          }
        }
      }
      return filtered;
    },
    updateGamePlayers(teamPlayers, playerOutId, playerInId) {
      const playerOut = teamPlayers.find(player => player.player_id === playerOutId);
      const playerIn = teamPlayers.find(player => player.player_id === playerInId);
      if (playerOut) {
        playerOut.changeStatus = 'o';
      }
      if (playerIn) {
        playerIn.changeStatus = 'i';
        playerIn.isActive = true;
      }
    },
  },
  created() {
    this.gameData = {
      id: this.gameForAnalysis.game_id,
      status: this.gameForAnalysis.game_status,
      dateTimeUTC: this.gameForAnalysis.date_time_utc,
      homeTeam: { ...this.homeTeam },
      awayTeam: { ...this.visitingTeam },
    };
    this.filteredCategoriesIds = this.getCategoriesIds(0)
      .concat(this.getCategoriesIds(1))
      .concat(this.getCategoriesIds(2));
  },
};
</script>

<style scoped>
.play-by-play {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
