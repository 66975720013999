<template>
  <div class="horizontal-field">
    <!--  Game Teams  -->
    <div class="horizontal-field__teams">
      <div v-if="gameStatus === 'awaiting'" class="horizontal-field__teams--awaiting" />
      <div class="horizontal-field__teams__home team">
        <div class="team-logo">
          <img :src="gameData.homeTeam.logo" alt="Equipo Local" />
        </div>
      </div>
      <div class="horizontal-field__teams__away team">
        <div class="team-logo">
          <img :src="gameData.awayTeam.logo" alt="Equipo Visitante" />
        </div>
      </div>
    </div>
    <!--  Field  -->
    <div class="horizontal-field__field" ref="soccerField">
      <div v-if="gameStatus === 'awaiting'" class="horizontal-field__field--awaiting">
        <div class="text">
          <div v-if="isToday">El partido iniciará a las {{ gameStart }}</div>
          <div v-else>Faltan {{ timeLeft }} para el inicio.</div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 740 285">
        <defs>
          <!--  Background definition  -->
          <pattern id="fieldBackground" width="1" height="1">
            <image xlink:href="/assets/icons/cancha_horizontal.png" preserveAspectRatio="none" id="image2" />
          </pattern>
          <!--  Teams arrows definition  -->
          <marker
            v-for="(color, key) in teamsColors"
            :key="`arrow-team-${key}`"
            :id="`arrow-${key}`"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="6"
            markerHeight="6"
            markerUnits="strokeWidth"
            orient="auto"
          >
            <path d="M 0 0 L 10 5 L 0 10 z" stroke-width="2" :fill="color" />
          </marker>
        </defs>
        <!-- Field group -->
        <g>
          <!-- Rectangle with field background -->
          <rect width="740" height="285" fill="url(#fieldBackground)" />
          <!-- Jugadas de 3 puntos -->
          <g v-for="play in plays3" :key="play.id" :id="`play-${play.playId}`">
            <line
              :x1="`${play.x1}%`"
              :y1="`${play.y1}%`"
              :x2="`${play.x2}%`"
              :y2="`${play.y2}%`"
              :stroke="play.strokeColor"
              stroke-width="2"
            />
            <line
              :x1="`${play.x2}%`"
              :y1="`${play.y2}%`"
              :x2="`${play.x3}%`"
              :y2="`${play.y3}%`"
              :stroke="play.strokeColor"
              stroke-width="2"
              :marker-end="play.arrowMarker"
            />
            <g @click="onPlayClicked($event, play)" class="play-jersey">
              <rect
                :x="`${play.x1}%`"
                :y="`${play.y1}%`"
                transform="translate(-11, -11)"
                height="22"
                width="22"
                stroke-width="2"
                fill="white"
                :stroke="play.strokeColor"
                rx="4"
                ry="4"
              />
              <text
                text-anchor="middle"
                dominant-baseline="middle"
                :x="`${play.x1}%`"
                :y="`${play.y1}%`"
                dy="1"
                font-size="12"
                stroke="black"
              >
                {{ play.player.jersey }}
              </text>
            </g>
          </g>
          <!-- Jugadas de 2 puntos -->
          <g v-for="play in plays2" :key="play.id" :id="`play-${play.playId}`">
            <line
              :x1="`${play.x1}%`"
              :y1="`${play.y1}%`"
              :x2="`${play.x2}%`"
              :y2="`${play.y2}%`"
              stroke-width="2"
              :stroke="play.strokeColor"
              :marker-end="play.arrowMarker"
            />
            <g @click="onPlayClicked($event, play)" class="play-jersey">
              <rect
                :x="`${play.x1}%`"
                :y="`${play.y1}%`"
                transform="translate(-10, -10)"
                height="20"
                width="20"
                stroke-width="2"
                fill="white"
                :stroke="play.strokeColor"
                rx="4"
                ry="4"
              />
              <text
                text-anchor="middle"
                dominant-baseline="middle"
                :x="`${play.x1}%`"
                :y="`${play.y1}%`"
                dy="1"
                font-size="12"
                stroke="black"
              >
                {{ play.player.jersey }}
              </text>
            </g>
          </g>
          <!-- Jugadas de 1 punto -->
          <g
            v-for="play in plays1"
            :key="play.id"
            :id="`play-${play.playId}`"
            @click="onPlayClicked($event, play)"
            class="play-jersey"
          >
            <rect
              :x="`${play.x1}%`"
              :y="`${play.y1}%`"
              transform="translate(-10, -10)"
              height="20"
              width="20"
              stroke-width="2"
              fill="white"
              :stroke="play.strokeColor"
              rx="4"
              ry="4"
            />
            <text
              text-anchor="middle"
              dominant-baseline="middle"
              :x="`${play.x1}%`"
              :y="`${play.y1}%`"
              dy="1"
              font-size="12"
              stroke="black"
            >
              {{ play.player.jersey }}
            </text>
          </g>
        </g>
      </svg>
    </div>
    <!-- Tooltip -->
    <PlayByPlayTooltip :play-data="playData" :show.sync="tooltip.isVisible" :x="tooltip.x" :y="tooltip.y" />
  </div>
</template>

<script>
import PlayByPlayTooltip from '@/components/Elements/NextMatches/PlayByPlayTooltip';
import offensiveChalkboardCategories from '@/data/team/offensiveChalkboardCategories';
import getTeamChalkboardPlayIndexes from '@/data/team/teamChalkboardPlayIndexes';
import PlayByPlayMixin from '@/mixins/play-by-play-mixin';
import axios from 'axios';

const teamChalkboardPlayIndexes = getTeamChalkboardPlayIndexes();
const initialPlayIdsOwnHalf = [213, 214];
let matchLapseRemainder = 1;

export default {
  name: 'PlayByPlayField',
  components: {
    PlayByPlayTooltip,
  },
  mixins: [PlayByPlayMixin],
  props: {
    gameData: {
      type: Object,
      required: true,
    },
    filteredCategoriesIds: {
      type: Array,
      default: () => [],
    },
    filteredPlayersIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      plays: [],
      plays1: [],
      plays2: [],
      plays3: [],
      gamePlayers: [],
      data: {
        x: 0,
        y: 0,
        visible: false,
      },
      playData: {
        playerName: '',
        minute: 0,
        rivalLogo: '',
        categoryName: '',
        xGi: 0,
        xGf: 0,
      },
      tooltip: {
        isVisible: false,
        x: 0,
        y: 0,
      },
      gameStatus: 'awaiting',
      channelName: '',
      playsPointsMap: new Map(),
    };
  },
  computed: {
    teamsColors() {
      if (this.gameData && this.gameData.homeTeam && this.gameData.awayTeam) {
        return {
          [this.gameData.homeTeam.team_id]: '#69f2ff',
          [this.gameData.awayTeam.team_id]: '#feff6b',
        };
      }
      return {};
    },
  },
  watch: {
    gameData: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.gameDateTime = new Date(newValue.dateTimeUTC);
          if (newValue.homeTeam && newValue.awayTeam) {
            this.fillLivePlays();
          }
          if (newValue.status !== 1) {
            this.gameStartTime = new Date(this.gameData.dateTimeUTC).valueOf();
            this.subscribeToGameChannel();
            this.subscribeToLineUpsChannel();
          } else {
            this.gameStatus = 'finished';
          }
        }
        this.checkTimeLeft();
      },
    },
    plays(newPlays) {
      if (newPlays.length && this.gameData.status !== 1) {
        this.gameStatus = 'live';
      }
    },
    filteredPlayersIds() {
      this.clearPointsPlays();
      this.plays.forEach(play => {
        this.filterPlay(play);
      });
    },
    filteredCategoriesIds() {
      this.clearPointsPlays();
      this.plays.forEach(play => {
        this.filterPlay(play);
      });
    },
    '$socket.connected': {
      immediate: true,
      handler(newValue) {
        if (newValue && this.gameData.id) {
          this.subscribeToGameChannel();
          this.subscribeToLineUpsChannel();
        }
      },
    },
  },
  methods: {
    async fillLivePlays() {
      try {
        const [lineUps, plays] = await Promise.all([this.fetchLineUps(), this.fetchPlays()]);
        if (lineUps.away_team && lineUps.away_team.players && lineUps.home_team && lineUps.home_team.players) {
          this.fillGamePlayers(
            lineUps.home_team.players,
            lineUps.away_team.players,
            lineUps.home_team.starting_lineup[0].lineup,
            lineUps.away_team.starting_lineup[0].lineup,
          );
        }
        this.plays = [...plays];
        this.plays.sort((a, b) => {
          if (a[teamChalkboardPlayIndexes.momentOfPlay] < b[teamChalkboardPlayIndexes.momentOfPlay]) {
            return -1;
          }
          if (a[teamChalkboardPlayIndexes.momentOfPlay] > b[teamChalkboardPlayIndexes.momentOfPlay]) {
            return 1;
          }
          return 0;
        });
        this.setMatchLapseRemainder(this.plays[0]);
        this.plays.forEach(play => {
          this.filterPlay(play);
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    checkActivePlayers(teamPlayers, teamLineUp) {
      teamPlayers.forEach(player => {
        const isActive = !!teamLineUp.find(lineUpPlayer => {
          return lineUpPlayer.player_id === player.player_id;
        });
        if (isActive) {
          player.isActive = isActive;
        }
      });
    },
    fillGamePlayers(homeTeamPlayers, awayTeamPlayers, homeLineUp, awayLineUp) {
      this.checkActivePlayers(homeTeamPlayers, homeLineUp);
      this.checkActivePlayers(awayTeamPlayers, awayLineUp);
      this.$emit('players-fetched', { homeTeamPlayers, awayTeamPlayers });
      this.gamePlayers = homeTeamPlayers.concat(awayTeamPlayers);
    },
    async fetchLineUps() {
      try {
        const response = await this.fetchRetry(
          `https://tj4cepyjc4.execute-api.us-west-2.amazonaws.com/prod/lineups/${this.gameData.id}`,
        );
        return response.data || {};
      } catch (e) {
        throw new Error(e);
      }
    },
    async fetchPlays() {
      try {
        const response = await this.fetchRetry(
          `https://grlrijtk4k.execute-api.us-west-2.amazonaws.com/prod/statisticsLive/${this.gameData.id}`,
        );
        return response && response.data && response.data.data ? response.data.data : [];
      } catch (e) {
        throw new Error(e);
      }
    },
    fetchRetry(url, options = null, retries = 3) {
      return axios
        .get(url, options)
        .then(response => response)
        .catch(e => {
          if (retries > 0) {
            return this.fetchRetry(url, options, retries - 1);
          }
          throw new Error(e);
        });
    },
    filterPlay(play) {
      if (!this.filteredCategoriesIds.includes(play[teamChalkboardPlayIndexes.categoryId])) {
        return;
      }
      if (!this.filteredPlayersIds.includes(play[teamChalkboardPlayIndexes.playerId])) {
        return;
      }
      for (const category of offensiveChalkboardCategories) {
        let points = 0;
        let playObject = null;
        if (category.categories && category.categories.includes(play[teamChalkboardPlayIndexes.categoryId])) {
          points = category.points.length;
          playObject = this.getPlayObject(play, 'team');
          playObject.arrowMarker = `url(#arrow-${playObject.teamId})`;
          playObject.strokeColor = this.teamsColors[playObject.teamId];
          playObject.categoryName = category.name;
        }
        if (category.rivalCategories && category.rivalCategories.includes(play[teamChalkboardPlayIndexes.categoryId])) {
          points = category.points.length;
          playObject = this.getPlayObject(play, 'rival');
          let flagTeamInvert = null;
          if (this.gameData.homeTeam.team_id === playObject.teamId) {
            flagTeamInvert = this.gameData.awayTeam.team_id;
          } else {
            flagTeamInvert = this.gameData.homeTeam.team_id;
          }
          playObject.arrowMarker = `url(#arrow-${flagTeamInvert})`;
          playObject.strokeColor = this.teamsColors[flagTeamInvert];
          playObject.categoryName = category.name;
        }
        if (points && playObject) {
          this[`plays${points}`].push(playObject);
          this.playsPointsMap.set(playObject.playId, points);
          break;
        }
      }
    },
    subscribeToGameChannel() {
      this.$socket.client.emit('subscribe', { channel: `game.${this.gameData.id}` });
    },
    subscribeToLineUpsChannel() {
      this.$socket.client.emit('subscribe', { channel: `lineups.game.${this.gameData.id}` });
    },
    getPercent(coordinateY) {
      return 100 - (1 - coordinateY) * 100 * 0.2;
    },
    getXOffset(coordinateY) {
      return (1 - coordinateY) * 100 * 0.1;
    },
    getPlayObject(play, flag) {
      const player = this.gamePlayers.find(player => player.player_id === play[teamChalkboardPlayIndexes.playerId]);
      let team = {};
      let rivalLogo = '';
      if (this.gameData.homeTeam.team_id === play[teamChalkboardPlayIndexes.teamId]) {
        team = { teamName: this.gameData.homeTeam.team_name, logo: this.gameData.homeTeam.logo };
        rivalLogo = this.gameData.awayTeam.logo;
      } else {
        team = { teamName: this.gameData.awayTeam.team_name, logo: this.gameData.awayTeam.logo };
        rivalLogo = this.gameData.homeTeam.logo;
      }
      if (play[teamChalkboardPlayIndexes.matchLapse] % 2 === matchLapseRemainder) {
        this.invertCoordinatesX(play);
      }
      if (play[teamChalkboardPlayIndexes.matchLapse] % 2 === 0) {
        this.invertCoordinatesY(play);
      }
      if (flag == 'rival') {
        this.invertCoordinates(play);
      }
      return {
        playId: play[teamChalkboardPlayIndexes.playId],
        gameId: play[teamChalkboardPlayIndexes.gameId],
        teamId: play[teamChalkboardPlayIndexes.teamId],
        playerId: play[teamChalkboardPlayIndexes.playerId],
        momentOfPlay: play[teamChalkboardPlayIndexes.momentOfPlay],
        categoryId: play[teamChalkboardPlayIndexes.categoryId],
        categoryType: play[teamChalkboardPlayIndexes.categoryType],
        matchLapse: play[teamChalkboardPlayIndexes.matchLapse],
        section: play[teamChalkboardPlayIndexes.section],
        matchResult: play[teamChalkboardPlayIndexes.matchResult],
        playingAs: play[teamChalkboardPlayIndexes.teamId] === this.gameData.homeTeam.team_id ? 1 : 2,
        total: play[teamChalkboardPlayIndexes.total],
        team,
        rivalLogo,
        player: player ? { name: player.name, jersey: player.jersey } : {},
        x1:
          play[teamChalkboardPlayIndexes.coordinate1X] * this.getPercent(play[teamChalkboardPlayIndexes.coordinate1Y]) +
          this.getXOffset(play[teamChalkboardPlayIndexes.coordinate1Y]),
        y1: play[teamChalkboardPlayIndexes.coordinate1Y] * 90 + 2,
        x2:
          play[teamChalkboardPlayIndexes.coordinate2X] * this.getPercent(play[teamChalkboardPlayIndexes.coordinate2Y]) +
          this.getXOffset(play[teamChalkboardPlayIndexes.coordinate2Y]),
        y2: play[teamChalkboardPlayIndexes.coordinate2Y] * 90 + 2,
        x3:
          play[teamChalkboardPlayIndexes.coordinate3X] * this.getPercent(play[teamChalkboardPlayIndexes.coordinate3Y]) +
          this.getXOffset(play[teamChalkboardPlayIndexes.coordinate3Y]),
        y3: play[teamChalkboardPlayIndexes.coordinate3Y] * 90 + 2,
        xGi: play[teamChalkboardPlayIndexes.xGi],
        xGf: play[teamChalkboardPlayIndexes.xGf],
      };
    },
    clearPointsPlays() {
      this.plays1 = [];
      this.plays2 = [];
      this.plays3 = [];
    },
    /**
     * @author Rigoberto Sanchez
     * @description Metodo para obtener los datos correspondientes a la jugada y mostrarlos en el tooltip
     */
    onPlayClicked(event, play) {
      this.playData.playerName = play.player.name || '';
      this.playData.minute = parseInt(play.momentOfPlay.split(':')[0]) || '';
      this.playData.rivalLogo = play.rivalLogo || '';
      this.playData.categoryName = play.categoryName || '';
      // this.playData.xGi = String(play.xGi);
      // this.playData.xGf = String(play.xGf);
      this.playData.team = play.team;
      const boundingClientRect = this.$refs.soccerField.getBoundingClientRect();
      this.tooltip.x = this.$refs.soccerField.clientWidth / 2 - 150 + boundingClientRect.left;
      this.tooltip.y = boundingClientRect.top - 10;
      this.tooltip.isVisible = true;
    },
    showModal(event, data, leagueAvg, names, local) {
      this.tooltip.data = data;
      this.tooltip.local = local;
      this.tooltip.names = names;
      this.tooltip.keyName = this.subcategoryName;
      this.tooltip.leagueAvg = leagueAvg;
      this.tooltip.x = event.pageX;
      if (!this.showPostMatchData) {
        this.tooltip.y = event.pageY > 700 ? event.pageY - 250 : event.pageY;
      } else {
        this.tooltip.y = event.pageY > 700 ? event.pageY - 70 : event.pageY;
      }
      this.tooltip.show = true;
    },
    deleteGamePlayFromChannel(gamePlayToDelete) {
      const points = this.playsPointsMap.get(gamePlayToDelete[teamChalkboardPlayIndexes.playId]);
      if (points) {
        const playIndex = this[`plays${points}`].findIndex(
          gamePlay => gamePlay.playId === gamePlayToDelete[teamChalkboardPlayIndexes.playId],
        );
        if (playIndex > -1) {
          this[`plays${points}`].splice(playIndex, 1);
        }
      }
      const playIndex = this.plays.findIndex(play => {
        return play[teamChalkboardPlayIndexes.playId] === gamePlayToDelete[teamChalkboardPlayIndexes.playId];
      });
      if (playIndex > -1) {
        this.plays.splice(playIndex, 1);
      }
    },
    setMatchLapseRemainder(firstPlay) {
      if (!firstPlay) return;
      const isOwnHalf = initialPlayIdsOwnHalf.includes(firstPlay[teamChalkboardPlayIndexes.categoryId]);
      const isHomeTeam = firstPlay[teamChalkboardPlayIndexes.teamId] === this.gameData.homeTeam.team_id;
      const isLeftHalf = firstPlay[teamChalkboardPlayIndexes.coordinate3X] <= 0.5;
      if (
        (isHomeTeam && isOwnHalf && isLeftHalf) ||
        (isHomeTeam && !isOwnHalf && !isLeftHalf) ||
        (!isHomeTeam && isOwnHalf && !isLeftHalf) ||
        (!isHomeTeam && !isOwnHalf && isLeftHalf)
      ) {
        matchLapseRemainder = 0;
      } else {
        matchLapseRemainder = 1;
      }
    },
    invertCoordinates(play) {
      if (!play.isIvertedX) {
        for (let i = 1; i < 4; i++) {
          play[teamChalkboardPlayIndexes[`coordinate${i}X`]] = 1 - play[teamChalkboardPlayIndexes[`coordinate${i}X`]];
        }
        play.isIvertedX = true;
      }
      if (!play.isInvertedY) {
        for (let i = 1; i < 4; i++) {
          play[teamChalkboardPlayIndexes[`coordinate${i}Y`]] = 1 - play[teamChalkboardPlayIndexes[`coordinate${i}Y`]];
        }
        play.isInvertedY = true;
      }
    },
    invertCoordinatesX(play) {
      if (!play.isIvertedX) {
        for (let i = 1; i < 4; i++) {
          play[teamChalkboardPlayIndexes[`coordinate${i}X`]] = 1 - play[teamChalkboardPlayIndexes[`coordinate${i}X`]];
          play.isIvertedX = true;
        }
      }
    },
    invertCoordinatesY(play) {
      if (!play.isInvertedY) {
        for (let i = 1; i < 4; i++) {
          play[teamChalkboardPlayIndexes[`coordinate${i}Y`]] = 1 - play[teamChalkboardPlayIndexes[`coordinate${i}Y`]];
          play.isInvertedY = true;
        }
      }
    },
  },
  sockets: {
    new(channelName, newGamePlay) {
      if (this.channelName !== channelName) return;
      if (this.plays.length === 0) {
        this.setMatchLapseRemainder(newGamePlay);
      }
      this.plays.push(newGamePlay);
      this.filterPlay(newGamePlay);
    },
    update(channelName, newGamePlay) {
      if (this.channelName !== channelName) return;
      this.deleteGamePlayFromChannel(newGamePlay);
      if (this.plays.length === 0) {
        this.setMatchLapseRemainder(newGamePlay);
      }
      this.plays.push(newGamePlay);
      this.filterPlay(newGamePlay);
    },
    delete(channelName, gamePlayToDelete) {
      if (this.channelName !== channelName) return;
      this.deleteGamePlayFromChannel(gamePlayToDelete);
    },
    new_lineup(channelName, newLineUp) {
      if (this.channelName !== channelName) return;
      this.fillGamePlayers(
        newLineUp.home_team.players,
        newLineUp.away_team.players,
        newLineUp.home_team.starting_lineup[0].lineup,
        newLineUp.away_team.starting_lineup[0].lineup,
      );
    },
  },
};
</script>

<style scoped lang="scss">
$arrowPercent: 100%;
$teamLogoSize: 76px;
$backgroundColor: #fcfcfc;
$logoPadding: 6px;
@mixin teamLogoContainer {
  background-repeat: no-repeat;
  background-size: $arrowPercent;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    height: $teamLogoSize;
    width: $teamLogoSize;
    padding: $logoPadding;
    background-color: $backgroundColor;

    & > img {
      object-fit: contain;
    }
  }
}

.horizontal-field {
  width: 100%;
  background-color: $backgroundColor;
  padding-bottom: 1rem;

  &__teams {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 70px;

    &--awaiting {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.6);
    }

    & > .team {
      padding: 18px 0;
      width: 55%;
    }

    &__home {
      background-image: url('/assets/icons/arrow_local@2x.png');
      justify-self: flex-end;
      @include teamLogoContainer;
    }

    &__away {
      background-image: url('/assets/icons/arrow_visita@2x.png');
      justify-self: flex-start;
      @include teamLogoContainer;
    }

    @media screen and (max-width: 760px) {
      .team-logo {
        width: 56px;
        height: 56px;
      }
    }

    @media screen and (max-width: 510px) {
      grid-column-gap: 40px;

      & > .team {
        width: 80%;
      }
    }
  }

  &__field {
    padding: 0 1rem;
    position: relative;

    &--awaiting {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 85%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0);

      & > div.text {
        color: white;
        font-size: 1rem;
        padding: 0.5rem 2rem;
        font-family: Avenir-Medium, sans-serif;
        font-weight: 500;
        border-radius: 24.5px;
        border: solid 3px #ffffff;
        background-color: rgba(29, 29, 29, 0.6);
      }
    }
  }

  & .play-jersey {
    cursor: pointer;

    &:hover {
      z-index: 3;
    }
  }
}
</style>
