import { minuteInMilliseconds, dayInMilliseconds, hourInMilliseconds } from '@/utils/date-and-time';

export default {
  data() {
    return {
      timeLeft: '',
      currentTime: new Date().valueOf(),
      interval: null,
      gameDateTime: new Date(),
      now: new Date(),
    };
  },
  computed: {
    isToday() {
      return (
        `${this.now.getFullYear()}.${this.now.getMonth()}.${this.now.getDate()}` ===
        `${this.gameDateTime.getFullYear()}.${this.gameDateTime.getMonth()}.${this.gameDateTime.getDate()}`
      );
    },
    gameStart() {
      return this.gameDateTime.toTimeString().slice(0, 5);
    },
  },
  methods: {
    checkTimeLeft() {
      const gameDateTime = new Date(this.gameData.dateTimeUTC).valueOf();
      if (gameDateTime > this.currentTime) {
        this.timeLeft = this.getTimeLeft(gameDateTime);
        this.interval = setInterval(() => {
          this.timeLeft = this.getTimeLeft(gameDateTime);
        }, 60000);
      } else {
        this.timeLeft = '00:00 hrs';
      }
    },
    /**
     *
     * @param {Number} gameDateTimeMilliseconds
     * @return {string}
     */
    getTimeLeft(gameDateTimeMilliseconds) {
      let diff = gameDateTimeMilliseconds.valueOf() - this.currentTime;
      const days = Math.floor(diff / dayInMilliseconds);
      diff = diff - days * dayInMilliseconds;
      const hours = Math.floor(diff / hourInMilliseconds);
      diff = diff - hours * hourInMilliseconds;
      const minutes = Math.floor(diff / minuteInMilliseconds);
      return `${days ? `${days} dias ` : ''}${hours < 10 ? `0${hours}` : hours}:${
        minutes < 10 ? `0${minutes}` : minutes
      } hrs.`;
    },
  },
  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
