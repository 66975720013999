import subMonths from 'date-fns/subMonths';

export const secondInMilliseconds = 1000;
export const minuteInMilliseconds = secondInMilliseconds * 60;
export const hourInMilliseconds = minuteInMilliseconds * 60;
export const dayInMilliseconds = hourInMilliseconds * 24;

const monthsNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
const monthsAbbreviations = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

/**
 *
 * @param {Date} date
 * @return {string}
 */
export function getMonthNameByDate(date = new Date()) {
  return monthsNames[date.getMonth()];
}

/**
 *
 * @param {number} index - must be a number between 0 and 11
 * @return {string}
 */
export function getMonthNameByIndex(index = 0) {
  return monthsNames[index];
}

/**
 *
 * @param {Date} date
 * @return {string}
 */
export function getMonthAbbreviationByDate(date = new Date()) {
  return monthsAbbreviations[date.getMonth()];
}

/**
 *
 * @param {number} index - must be a number between 0 and 11
 * @return {string}
 */
export function getMonthAbbreviationByIndex(index = 0) {
  return monthsAbbreviations[index];
}

/**
 * @param {Date} date
 * @param {number} monthsToSubtract
 * @return {Date | *}
 */
export function subtractMonths(date = new Date(), monthsToSubtract = 0) {
  return subMonths(date, monthsToSubtract);
}
