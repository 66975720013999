<template>
  <div
    ref="timeLineEvent"
    class="time-line-event"
    :class="teamTypeClasses"
    :style="{ width: `${width}px` }"
    :key="gameEventKey"
  >
    <div class="time-line-event__details">
      <div class="time-line-event__details__event-name">
        {{
          gameEvent.category_id
            ? gameEvent.event_type_id === 5
              ? gameEvent.category_name
              : gameEvent.event_type_name
            : gameEvent.comments
        }}
      </div>
      <div class="time-line-event__details__comment">
        {{ this.gameEvent.category_id ? `${this.gameEvent.comments}` : '' }}
      </div>
      <div
        v-if="gameEvent.video_link != '-'"
        class="time-line-event__details__video"
        @click="showVideoTimeline(gameEvent.video_link)"
      >
        Ver Video <img class="time-line-event__details__video__icon" src="/icons/icn-play.png" />
      </div>
      <!--<div class="time-line-event__details__player">
        {{ playerName }}
      </div>-->
    </div>
    <div v-if="gameEvent.icon" class="time-line-event__icon" :class="iconClasses">
      <img :src="`/icons/mxm/${gameEvent.icon}.svg`" alt="Event icon" />
    </div>
    <div class="time-line-event__separator" />
    <div class="time-line-event__minute">{{ gameEvent.minute }}'</div>
  </div>
</template>

<script>
const eventsWithOutPlayer = [1, 2, 3, 4, 6];
import { mapMutations } from 'vuex';
export default {
  name: 'TimeLineEvent',
  props: {
    /**
     * {{
     * category_name: string,
     * comments: string,
     * video_status: number,
     * season_id: number,
     * team_id: number,
     * event_type_name: string,
     * team_name: string,
     * event_type_id: number,
     * minute: number,
     * createdAt: string,
     * play_id: string,
     * total: number,
     * player_id: string,
     * video_link: string,
     * category_id: number,
     * x: number,
     * y: number,
     * player_name: string,
     * match_lapse: number,
     * game_id: number,
     * status: number
     * }}
     * example object:
     * {
            "category_name": "Fuera de Lugar",
            "comments": "Fuera de lugar de Oribe Peralta Morones",
            "video_status": 0,
            "season_id": 1325,
            "team_id": 1,
            "event_type_name": "Jugada",
            "team_name": "Guadalajara",
            "event_type_id": 5,
            "minute": 3,
            "createdAt": "-",
            "play_id": "62209812021193252",
            "total": 1,
            "player_id": "363",
            "video_link": "-",
            "category_id": 17,
            "x": 68,
            "y": 81,
            "player_name": "Peralta Morones, Oribe",
            "match_lapse": 1,
            "game_id": 62209,
            "status": 1
        }
     */
    gameEvent: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 352,
    },
  },
  data() {
    return {
      lastHeight: 0,
      gameEventKey: 0,
    };
  },
  computed: {
    teamTypeClasses() {
      const baseClass = `time-line-event--${this.gameEvent.teamType === 1 ? 'home' : 'away'}-team`;
      return [baseClass, `${baseClass}-${this.isMobile ? 'mobile' : 'desktop'}`];
    },
    iconClasses() {
      const iconClasses = [`time-line-event__icon--desktop-${this.gameEvent.teamType === 1 ? 'home' : 'away'}-team`];
      if (!this.isMobile) {
        iconClasses.push('time-line-event__icon--desktop');
      }
      return iconClasses;
    },
    playerName() {
      return this.gameEvent.category_id === 28 || eventsWithOutPlayer.includes(this.gameEvent.event_type_id)
        ? ''
        : this.gameEvent.player_name;
    },
  },
  watch: {
    width() {
      this.emitHeight();
    },
    gameEvent: {
      deep: true,
      handler() {
        this.gameEventKey++;
      },
    },
  },
  methods: {
    ...mapMutations('general', ['setVideoModalVisibility', 'setVideoSrc']),
    showVideoTimeline(video) {
      this.setVideoSrc(video);
      this.setVideoModalVisibility(true);
    },
    getIconClasses() {
      return [
        'time-line-event__icon--desktop',
        `time-line-event__icon--desktop-${this.gameEvent.teamType === 1 ? 'home' : 'away'}-team`,
      ];
    },
    emitHeight() {
      setTimeout(() => {
        if (
          this.$refs.timeLineEvent &&
          this.$refs.timeLineEvent.clientHeight &&
          this.$refs.timeLineEvent.clientHeight !== this.lastHeight
        ) {
          this.lastHeight = this.$refs.timeLineEvent.clientHeight;
          this.$emit('event-height', this.lastHeight);
        }
      }, 500);
    },
  },
  mounted() {
    this.emitHeight();
    if (this.gameEvent.event_type_id === 3) {
      const [playerOut, playerIn] = this.gameEvent.player_id.split('@');
      this.$emit('player-changed', {
        playerOut: Number(playerOut),
        playerIn: Number(playerIn),
        teamId: this.gameEvent.team_id,
      });
    }
  },
};
</script>

<style scoped lang="scss">
$spaceToLine: 40px;

.time-line-event {
  background-color: white;
  padding: 12px 6px;
  border-radius: 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  box-shadow: 2px 1px 18px 0 rgba(0, 0, 0, 0.15);
  width: 22rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;

  & > div {
    margin: 0 6px;
  }

  &__details {
    font-family: Circular-Std-Book, sans-serif;
    color: #4b4b4b;
    flex-grow: 1;
    text-align: left;
    &__event-name {
      color: #68b429;
      font-weight: bold;
    }
    &__video {
      font-family: 'Circular-Std-Book';
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      display: inline-flex;
      letter-spacing: -0.17px;
      line-height: 10px;
      padding-top: 14px;
      color: #4b4b4b;
      @media screen and (max-width: 300px) {
        font-size: 14px;
      }
      &__icon {
        margin-left: 5px;
        margin-top: -6px;
      }
    }
    &__video:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  &__icon {
    flex-shrink: 0;
    height: 44px;
    width: 44px;
    //border-radius: 50%;
    //border: solid 2.4px #88de40;
    //background-color: #3e3e3e;
    z-index: 1;

    & > img {
      object-fit: contain;
    }

    &--desktop {
      position: absolute;
      top: 50%;

      & > img {
        position: relative;
        top: -50%;
      }

      &-home-team {
        right: -68px;
      }

      &-away-team {
        left: -68px;
      }
    }
  }

  &__separator {
    min-height: 2.5rem;
    height: 100%;
    border-left: dotted 1px #4c4c4c;
  }

  &__minute {
    text-align: center;
    font-size: 1.4rem;
    font-family: Circular-Std-Book, sans-serif;
    font-weight: bold;
  }

  &--home-team {
    position: relative;

    &-desktop:after {
      content: ' ';
      height: 0;
      position: absolute;
      width: 0;
      right: -20px;
      border: 10px solid transparent;
      border-left-color: white;
    }

    &-mobile:after {
      content: ' ';
      height: 0;
      position: absolute;
      width: 0;
      left: -20px;
      border: 10px solid transparent;
      border-right-color: white;
    }
  }

  &--away-team {
    position: relative;
    flex-direction: row-reverse;

    &-desktop:after {
      content: ' ';
      height: 0;
      position: absolute;
      width: 0;
      left: -20px;
      border: 10px solid transparent;
      border-right-color: white;
    }

    &-mobile:after {
      content: ' ';
      height: 0;
      position: absolute;
      width: 0;
      right: -20px;
      border: 10px solid transparent;
      border-left-color: white;
    }
  }
}
</style>
