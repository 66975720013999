<template>
  <div class="filter-row" v-if="player.isActive">
    <div class="filter-row__player-number">
      {{ player.jersey }}
    </div>
    <div class="filter-row__player-photo">
      <img :src="player.photo" alt="" />
    </div>
    <div class="filter-row__player-name">
      {{ player.name }}
    </div>
    <div class="filter-row__player-change">
      <img
        v-if="player.changeStatus"
        :src="`/assets/icons/icn_${player.changeStatus === 'o' ? 'salio' : 'ingreso'}.png`"
        alt="cambio"
      />
    </div>
    <div class="filter-row__player-check">
      <div>
        <BCheckbox v-model="player.isSelected" />
      </div>
    </div>
  </div>
</template>

<script>
import { BCheckbox } from 'buefy/dist/esm/checkbox';

export default {
  name: 'PlayersFilterRow',
  components: {
    BCheckbox,
  },
  props: {
    player: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.filter-row {
  display: grid;
  grid-template-columns: 28px 28px 1fr 26px 30px;
  column-gap: 10px;
  box-shadow: inset 0 -1px 0 0 rgba(203, 203, 203, 0.5);
  background-color: white;
  padding: 0.25rem;

  &__player {
    &-number {
      width: 2.5rem;
      justify-self: right;
      text-align: right;
      font-family: Avenir-Demi, sans-serif;
      font-size: 0.8rem;
    }

    &-name {
      text-align: left;
      justify-self: left;
      font-family: Avenir-Pro-Medium, sans-serif;
    }

    &-photo {
      height: 1.75rem;

      & > img {
        height: 1.75rem;
        object-fit: contain;
      }

      @media screen and (max-width: 767px) {
        height: 2.5rem;

        & > img {
          height: 2.5rem;
        }
      }
    }
    &-check {
      justify-self: center;
      align-self: center;
      height: 20px;

      & > div {
        height: fit-content;
      }
    }
  }

  & > div {
    align-self: center;
  }
}
</style>
<style lang="scss">
.filter-row {
  & .b-checkbox.checkbox:not(.button) {
    margin-right: 0 !important;
  }
}
</style>
