<template>
  <BModal
    :active="showModal"
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    aria-modal
    :can-cancel="false"
    @close="$emit('close')"
  >
    <div id="players-filter" class="players-filter">
      <PlayersFilterDesktop
        v-if="displayWidth > 767"
        :teams="teams"
        :game-players="gamePlayers"
        @close="applyFiltersAndClose"
      />
      <PlayersFilterMobile v-else :teams="teams" :game-players="gamePlayers" @close="applyFiltersAndClose" />
      <div class="players-filter__footer">
        <div class="players-filter__footer__button" @click="applyFiltersAndClose">OK</div>
      </div>
    </div>
  </BModal>
</template>

<script>
import PlayersFilterDesktop from '@/components/Elements/NextMatches/PlayersFilterDesktop';
import PlayersFilterMobile from '@/components/Elements/NextMatches/PlayersFilterMobile';
import { BModal } from 'buefy/dist/esm/modal';
import { mapState } from 'vuex';

export default {
  name: 'PlayersFilterModal',
  components: {
    BModal,
    PlayersFilterDesktop,
    PlayersFilterMobile,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    teams: {
      type: Object,
      required: true,
    },
    gamePlayers: {
      type: Object,
      default: () => {
        return {
          homeTeamPlayers: [],
          awayTeamPlayers: [],
        };
      },
    },
  },
  computed: {
    ...mapState(['displayWidth']),
  },
  methods: {
    applyFiltersAndClose() {
      this.$emit('apply-players-filter');
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.players-filter {
  margin: 1rem;

  &__footer {
    background-color: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    padding: 2rem;

    &__button {
      background-color: #4b4b4b;
      color: white;
      padding: 0.875rem 7.3rem;
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
      cursor: pointer;
    }

    @media screen and (max-width: 767px) {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      padding: 1.25rem;
    }
  }
}
</style>
<style lang="scss">
#players-filter {
  $primary: #7dd421;
  // checkboxes
  .b-checkbox.checkbox input[type='checkbox']:checked + .check {
    background: $primary
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:white' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
      no-repeat center center;
    border-color: $primary;
  }

  .b-checkbox.checkbox:hover input[type='checkbox']:not(:disabled) + .check {
    border-color: $primary;
  }

  .b-checkbox.checkbox input[type='checkbox']:focus:checked + .check {
    box-shadow: 0 0 0.5em rgba(125, 212, 33, 0.8);
  }
}
</style>
