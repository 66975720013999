<template>
  <div class="filter-subheader">
    <div class="filter-subheader__text">
      Jugadores que participaron
    </div>
    <div class="filter-subheader__icon">
      <div />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayersFilterSubHeader',
};
</script>

<style scoped lang="scss">
.filter-subheader {
  display: flex;
  position: relative;
  justify-content: center;
  box-shadow: inset 0 4px 3px -3px rgba(0, 0, 0, 0.15);
  background-color: #f4f4f4;
  width: 100%;
  padding: 0.75rem 0;

  &__text {
    font-family: Avenir-Demi, sans-serif;
    font-size: 0.87rem;
  }

  &__icon {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 1.3px;
    background-color: #565656;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      width: 10px;
      height: 3px;
      background-color: #f4f4f4;
    }

    @media screen and (max-width: 767px) {
      right: 14px;
    }
  }

  @media screen and (max-width: 767px) {
    justify-content: left;

    &__text {
      margin-left: 1rem;
    }
  }
}
</style>
