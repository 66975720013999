<template>
  <div class="players-filter-mobile">
    <div class="players-filter-mobile__header">
      <div class="players-filter-mobile__header__title">
        filtrar jugadores
      </div>
      <div class="players-filter-mobile__header__back" @click="$emit('close')">
        <div class="players-filter-mobile__header__back__icon">
          <img src="/icons/icn_back.svg" alt="back" />
        </div>
        <div class="players-filter-mobile__header__back__text">volver</div>
      </div>
    </div>
    <div class="players-filter-mobile__teams">
      <div
        class="players-filter-mobile__teams__team"
        :class="{ 'players-filter-mobile__teams__team--selected': selectedTeam === 1 }"
      >
        <div class="players-filter-mobile__teams__team__name">{{ teams.homeTeam.acronym }}</div>
        <div
          class="players-filter-mobile__teams__team__logo"
          :class="{ 'players-filter-mobile__teams__team__logo--selected': selectedTeam === 1 }"
          @click="selectedTeam = 1"
        >
          <img :src="teams.homeTeam.logo" alt="Equipo Local" />
        </div>
      </div>
      <div
        class="players-filter-mobile__teams__team"
        :class="{ 'players-filter-mobile__teams__team--selected': selectedTeam === 2 }"
      >
        <div
          class="players-filter-mobile__teams__team__logo"
          :class="{ 'players-filter-mobile__teams__team__logo--selected': selectedTeam === 2 }"
          @click="selectedTeam = 2"
        >
          <img :src="teams.awayTeam.logo" alt="Equipo Visitante" />
        </div>
        <div class="players-filter-mobile__teams__team__name">{{ teams.awayTeam.acronym }}</div>
      </div>
    </div>
    <PlayersFilterSubHeader />
    <PlayersFilterRow v-for="(player, index) in selectedTeamPlayers" :key="index" :player="player" />
  </div>
</template>

<script>
import PlayersFilterSubHeader from '@/components/Elements/PlayersFilterSubHeader';
import PlayersFilterRow from '@/components/Elements/PlayersFilterRow';

export default {
  name: 'PlayersFilterMobile',
  components: {
    PlayersFilterSubHeader,
    PlayersFilterRow,
  },
  props: {
    teams: {
      type: Object,
      required: true,
    },
    gamePlayers: {
      type: Object,
      default: () => {
        return {
          homeTeamPlayers: [],
          awayTeamPlayers: [],
        };
      },
    },
  },
  data() {
    return {
      // 1 for home team and 2 for away team
      selectedTeam: 1,
    };
  },
  computed: {
    selectedTeamPlayers() {
      return this.selectedTeam === 1 ? this.gamePlayers.homeTeamPlayers : this.gamePlayers.awayTeamPlayers;
    },
  },
};
</script>

<style scoped lang="scss">
.players-filter-mobile {
  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background-color: #4b4b4b;
    color: white;
    text-transform: uppercase;

    &__title {
      font-size: 1.125rem;
      margin: 0.8rem 0;
    }

    &__back {
      display: flex;
      position: absolute;
      left: 23px;
      height: 100%;
      align-items: center;

      &__icon {
        height: 19px;
      }

      &__text {
        margin-top: 0.1rem;
        margin-left: 0.5rem;
        font-size: 0.85rem;
        font-family: Circular-Std-Book, sans-serif;

        @media screen and (max-width: 474px) {
          display: none;
        }
      }
    }
  }

  &__teams {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    padding: 1rem 0;

    &__team {
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.5;

      &__logo {
        width: 5rem;
        height: 5rem;
        margin: 0 1rem;
        flex-shrink: 0;
        padding: 0.5rem;
        border-radius: 8px;
        cursor: pointer;

        &--selected {
          border: solid 4px #86b6ff;
        }
      }

      &__name {
        margin: 0 1rem;
        text-transform: uppercase;
        font-family: Circular-Std-Book, sans-serif;
        width: fit-content;
        flex-shrink: 1;
        font-size: 1.5rem;

        @media screen and (max-width: 448px) {
          display: none;
        }
      }

      &--selected {
        opacity: 1;
      }
    }
  }
}
</style>
