import { getPlayValueIndexesFromArray } from '@/utils/misc';

/**
 * Defines the index position of each value inside a team stat play.
 */
const teamStatPlayValues = [
  'playId',
  'gameId',
  'teamId',
  'playerId',
  'momentOfPlay',
  'categoryId',
  'categoryType',
  'matchLapse',
  'section',
  'matchResult',
  'playingAs',
  'total',
  'coordinate1X',
  'coordinate1Y',
  'coordinate2X',
  'coordinate2Y',
  'coordinate3X',
  'coordinate3Y',
  'xGi',
  'xGf',
];

let teamStatPlayIndex = null;

export default function getTeamStatPlayIndexes() {
  if (!teamStatPlayIndex) {
    teamStatPlayIndex = getPlayValueIndexesFromArray(teamStatPlayValues);
  }
  return teamStatPlayIndex;
}
