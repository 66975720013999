<template>
  <div ref="timeLine" class="time-line">
    <div class="time-line__game-status">
      <div v-if="timeLineStatus === 'awaiting'" class="time-line__game-status--awaiting">
        <div v-if="isToday">El partido iniciará a las {{ gameStart }}</div>
        <div v-else>Faltan {{ timeLeft }} para el inicio.</div>
      </div>
      <div v-if="false" class="time-line__game-status--live">{{ currentStatus.abbreviation }} {{ timer }}</div>
      <div v-if="timeLineStatus === 'finished'" class="time-line__game-status--finished">Fin {{ lastMinute }}</div>
    </div>
    <div class="time-line__game-events">
      <TransitionGroup :name="transitionName" tag="div">
        <div class="time-line__game-events__grid" v-for="(gameEvent, index) in gameEvents" :key="gameEvent.play_id">
          <div :ref="`gameEventDiv${index}`" :class="getGameEventClassAndSetIcon(gameEvent, index)">
            <div :class="getEventContainerClass(gameEvent)">
              <TimeLineEvent
                :game-event="gameEvent"
                :is-mobile="isMobile"
                :width="gameEventWidth"
                @event-height="updateContainerHeight($event, index)"
                @player-changed="$emit('player-changed', $event)"
              />
            </div>
            <div :ref="`gameEventLine${index}`" class="line" v-if="index > 0" />
            <div class="start-line" v-if="gameData.status === 1 && index === gameEvents.length - 1">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>
<script>
import TimeLineEvent from './TimeLineEvent';
import axios from 'axios';
import PlayByPlayMixin from '@/mixins/play-by-play-mixin';

const eventsIcons = {
  '1-1': 'gol',
  '1-2': 'gol',
  '1-3': 'gol',
  '1-4': 'gol',
  '1-5': 'gol',
  '1-6': 'gol',
  '1-7': 'gol',
  '1-12': 'gol',
  '1-13': 'gol',
  '1-16': 'gol',
  '2-8': 'amonestacion',
  '2-9': 'expulsion',
  '3-10': 'cambio_de_jugador',
  '3-11': 'cambio_de_jugador',
  '4-14': 'gol',
  '4-15': 'penal_fallado',
  '5-17': 'fuera_de_lugar',
  '5-18': 'tiro_de_esquina',
  '5-20': 'tiro_a_gol',
  '5-21': 'falta_cometida',
  '5-22': 'penal_marcado',
  '5-23': 'penal_fallado',
  '5-24': '',
  '5-25': '',
  '5-26': 'jugada_peligro_dentro_area',
  '5-27': 'atajada',
  '5-28': 'tiempo_agregado',
  '5-29': 'presion_para_anotar',
  '7-30': 'revision_var',
  '7-31': 'revision_var',
  '7-32': 'revision_var',
  '7-33': 'revision_var',
  '7-34': 'revision_var',
};
const timeLineStatusValues = {
  3: { name: 'Inicia Primer Tiempo', weight: 1, playTimer: 1, abbreviation: '1T:' },
  4: { name: 'Final Primer Tiempo', weight: 2, playTimer: 0, abbreviation: '1T:' },
  5: { name: 'Inicia Segundo Tiempo', weight: 3, playTimer: 1, abbreviation: '2T:' },
  8: { name: 'Final Segundo Tiempo', weight: 4, playTimer: 0, abbreviation: '2T:' },
  9: { name: 'Inicia Primer Tiempo Extra', weight: 5, playTimer: 1, abbreviation: '1T extra:' },
  12: { name: 'Final Primer Tiempo Extra', weight: 6, playTimer: 0, abbreviation: '1T extra:' },
  10: { name: 'Inicia Segundo Tiempo Extra', weight: 7, playTimer: 1, abbreviation: '2T extra:' },
  13: { name: 'Final Segundo Tiempo Extra', weight: 8, playTimer: 0, abbreviation: '2T extra:' },
  11: { name: 'Inicia Tanda de Penales', weight: 9, playTimer: 1, abbreviation: 'Penales:' },
  15: { name: 'Final Tanda de Penales', weight: 10, playTimer: 0, abbreviation: 'Penales:' },
  6: { name: 'Marcador Final', weight: 11, playTimer: 0, abbreviation: 'Final' },
};
let timeLineResizeObserver = null;

export default {
  name: 'TimeLine',
  components: {
    TimeLineEvent,
  },
  mixins: [PlayByPlayMixin],
  props: {
    gameData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      gameEvents: [],
      gameEventsQueue: [],
      gameEventsUrl: 'https://al2nv2dmkc.execute-api.us-west-2.amazonaws.com/prod/mxm/',
      transitionName: '',
      subscriptionInfo: {
        gameId: 0,
        isSubscribed: false,
      },
      areGameEventsFilled: false,
      timeLineWidth: 812,
      eventContainerWidth: 0,
      channelName: '',
      timeLineStatus: 'awaiting', // possible values are: 'awaiting', 'started', 'paused', 'finished'
      timeLeft: '',
      lastMinute: 0,
      timeLineStatuses: [],
      currentStatus: { abbreviation: '' },
      timer: '00:00',
    };
  },
  computed: {
    isMobile() {
      return this.timeLineWidth < 580;
    },
    gameEventWidth() {
      if (this.isMobile) {
        return this.timeLineWidth * 0.85;
      }
      return this.timeLineWidth / 2 - 70;
    },
  },
  watch: {
    gameData: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.id) {
          this.gameDateTime = new Date(this.gameData.dateTimeUTC);
          this.gameEventsQueue = [];
          this.timeLineStatuses = [];
          this.transitionName = '';
          this.checkTimeLeft();
          if (newValue.status !== 1) {
            this.subscribeToMXMChannel();
          } else {
            this.timeLineStatus = 'finished';
          }
          this.fillGameEvents(newValue.id);
        }
      },
    },
    '$socket.connected': {
      immediate: true,
      handler(newValue) {
        if (newValue && this.gameData.id) {
          this.subscribeToMXMChannel();
        }
      },
    },
    gameEvents(newGameEvents) {
      if (newGameEvents.length && this.gameData.status !== 1) {
        this.timeLineStatus = 'live';
      }
    },
  },
  methods: {
    async fillGameEvents(gameId) {
      try {
        const gameEvents = await this.fetchGameEvents(gameId);
        if (gameEvents) {
          this.gameEvents = [...gameEvents];
          this.checkGameEventsQueue();
          this.sortGameEvents();
          setTimeout(() => {
            this.transitionName = 'list';
          }, 500);
        }
        if (this.gameData.status === 1) {
          this.lastMinute = `${this.gameEvents[this.gameEvents.length - 1].minute}'`;
        } else {
          this.checkTimeLineStatus();
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    getGameEventClassAndSetIcon(gameEvent, eventIndex) {
      gameEvent.icon =
        eventIndex === 0 ? 'inicio_del_partido' : eventsIcons[`${gameEvent.event_type_id}-${gameEvent.category_id}`];
      if (gameEvent.team_id === this.gameData.homeTeam.team_id || !gameEvent.team_id) {
        gameEvent.teamType = 1;
        return ['home-team-event'];
      }
      gameEvent.teamType = 2;
      return ['away-team-event'];
    },
    getEventContainerClass() {
      if (this.isMobile) {
        return ['time-line-event__container--mobile'];
      }
      return ['time-line-event__container--desktop'];
    },
    async fetchGameEvents(gameId) {
      try {
        const {
          data: { plays },
        } = await axios.get(`${this.gameEventsUrl}${gameId}`);
        return plays;
      } catch (e) {
        throw new Error(e);
      }
    },
    subscribeToMXMChannel() {
      this.channelName = `mxm.game.${this.gameData.id}`;
      this.$socket.client.emit('subscribe', { channel: this.channelName });
      this.isSubscribed = true;
    },
    checkGameEventsQueue() {
      if (this.gameEventsQueue.length) {
        this.gameEvents.push(...this.gameEventsQueue);
      }
      this.areGameEventsFilled = true;
    },
    setUpResizeObserver() {
      if (window.ResizeObserver) {
        timeLineResizeObserver = new ResizeObserver(() => {
          this.timeLineWidth = this.$refs.timeLine.clientWidth;
        });
        timeLineResizeObserver.observe(this.$refs.timeLine);
      } else {
        throw new Error('Resize observer not supported!');
      }
    },
    updateContainerHeight(eventHeight, index) {
      const gameEventDiv = this.$refs[`gameEventDiv${index}`][0];
      const gameEventLine = this.$refs[`gameEventLine${index}`] ? this.$refs[`gameEventLine${index}`][0] : null;
      const totalHeight = eventHeight + 12;
      if (gameEventDiv) {
        gameEventDiv.style.height = `${totalHeight}px`;
      }
      if (gameEventLine) {
        gameEventLine.style.height = `${totalHeight}px`;
      }
    },
    sortGameEvents() {
      this.gameEvents.sort((a, b) => {
        return (
          this.getMatchLapseMinutes(a.match_lapse) +
          Number(a.minute) -
          (this.getMatchLapseMinutes(b.match_lapse) + Number(b.minute))
        );
      });
    },
    getMatchLapseMinutes(matchLapse) {
      let matchLapseMinutes = 0;
      switch (matchLapse) {
        case 1:
          matchLapseMinutes = 0;
          break;
        case 2:
          matchLapseMinutes = 45;
          break;
        case 3:
          matchLapseMinutes = 90;
          break;
        case 4:
          matchLapseMinutes = 105;
          break;
        case 5:
          matchLapseMinutes = 120;
          break;
      }
      return matchLapseMinutes;
    },
    addGameEventFromChannel(newGameEvent) {
      if (this.areGameEventsFilled) {
        this.gameEvents.push(newGameEvent);
        this.sortGameEvents();
      } else {
        this.gameEventsQueue.push(newGameEvent);
      }
    },
    deleteGameEventFromChannel(gameEventToDelete) {
      const eventIndex = this.gameEvents.findIndex(gameEvent => gameEvent.play_id === gameEventToDelete.play_id);
      if (eventIndex) {
        this.gameEvents.splice(eventIndex, 1);
      }
    },
    checkTimeLineStatus() {
      this.gameEvents.forEach(gameEvent => {
        const timeLineStatus = timeLineStatusValues[gameEvent.game_status];
        if (timeLineStatus) {
          this.timeLineStatuses.push({
            ...timeLineStatus,
            gameEvent,
          });
        }
      });
      this.timeLineStatuses.sort((a, b) => {
        return b.weight - a.weight;
      });
      const currentStatus = this.timeLineStatuses[0];
      if (currentStatus) {
        this.currentStatus = currentStatus;
        if (currentStatus.playTimer) {
          this.startTimer();
        }
      }
    },
    startTimer() {},
  },
  mounted() {
    this.$nextTick(() => {
      this.setUpResizeObserver();
    });
  },
  beforeDestroy() {
    if (timeLineResizeObserver) {
      timeLineResizeObserver.unobserve(this.$refs.timeLine);
    }
  },
  sockets: {
    new_mxm(channelName, newGameEvent) {
      if (this.channelName !== channelName) return;
      this.addGameEventFromChannel(newGameEvent);
    },
    update_mxm(channelName, newGameEvent) {
      if (this.channelName !== channelName) return;
      this.deleteGameEventFromChannel(newGameEvent);
      this.addGameEventFromChannel(newGameEvent);
    },
    delete_mxm(channelName, gameEventToDelete) {
      if (this.channelName !== channelName) return;
      this.deleteGameEventFromChannel(gameEventToDelete);
    },
  },
};
</script>

<style scoped lang="scss">
$gameEventHeight: 80px;
$spaceToLine: 40px;

.time-line {
  background-color: #fafafa;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 14px 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__game-status {
    font-family: Avenir-Demi, sans-serif;
    line-height: 1rem;
    color: #4b4b4b;

    &--live {
      padding: 10px 8px 8px;
      border-radius: 5px;
      border: solid 2px #4b4b4b;
    }

    &--awaiting {
      padding: 10px 8px 8px;
      border-radius: 5px;
      border: solid 2px #4b4b4b;
    }

    &--finished {
      padding: 10px 8px;
      position: relative;

      &__line-end {
      }
    }
  }

  &__game-events {
    width: 100%;
    padding-top: 66px;

    // this div is for the div mounted by transition group
    & > div {
      display: flex;
      flex-direction: column-reverse;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      & > div {
        position: relative;
        min-height: $gameEventHeight;
        height: fit-content;

        &.home-team-event {
          width: 100%;
          grid-column-start: 1;
          grid-column-end: 2;
          justify-self: end;
          padding-right: $spaceToLine;

          & > .time-line-event__container--desktop {
            position: absolute;
            top: -40px;
            right: $spaceToLine;
          }

          & > .time-line-event__container--mobile {
            position: absolute;
            top: -40px;
            left: 12px;
          }

          & > div.line {
            min-height: $gameEventHeight;
            position: absolute;
            top: 0;
            right: -2px;
            border-right: solid 4px #88de40;
          }

          & > div.start-line {
            position: absolute;
            top: -68px;
            right: -12px;
            width: 24px;
            height: 180px;
            display: grid;
            border-top: solid 4px #88de40;
            grid-template-columns: 1fr 1fr;

            & > div:first-child {
              border-right: solid 2px #88de40;
            }

            & > div:last-child {
              border-left: solid 2px #88de40;
            }
          }
        }

        &.away-team-event {
          width: 100%;
          grid-column-start: 2;
          grid-column-end: 3;
          padding-left: 40px;

          & > .time-line-event__container--desktop {
            position: absolute;
            top: -40px;
          }

          & > .time-line-event__container--mobile {
            position: absolute;
            top: -40px;
            right: 12px;
          }

          & > div.line {
            min-height: $gameEventHeight;
            position: absolute;
            top: 0;
            left: -2px;
            border-left: solid 4px #88de40;
          }

          & > div.start-line {
            position: absolute;
            top: -68px;
            left: -12px;
            width: 24px;
            height: 180px;
            display: grid;
            border-top: solid 4px #88de40;
            grid-template-columns: 1fr 1fr;

            & > div:first-child {
              border-right: solid 2px #88de40;
            }

            & > div:last-child {
              border-left: solid 2px #88de40;
            }
          }
        }
      }
    }
  }
}
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
